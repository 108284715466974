

import { Auth, Amplify } from 'aws-amplify';
import awsconfig from '../../../aws-exports';
import { useContext, useState } from 'react';
import { DataContext } from '../../../context/DataContext';
import { Link, useNavigate } from 'react-router-dom';
import ErrorAlert from '../../../components/common/alerts/ErrorAlert';
//import logo from './../../../assets/images/logo-siayec.png';
import InputForm from '../../../components/form/InputForm';
import Loader from '../../../components/common/Loader';

Amplify.configure(awsconfig, {
    Routers: {
        routerName: 'browserRouter',
        baseRedirect: '/index.html'
    }
});

const LoginForm = () => {
    // Variables to get user login fields
    const [error, setError] = useState('')
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [loading, setLoading] = useState(false);

    //Using context to get and set UserData
    const { setUserData, setAccessToken } = useContext(DataContext)
    const navigate = useNavigate();

    // Submit function that use Auth from amplify and set userData in userData Context
    const handleSubmit = async (event) => {
        event.preventDefault();
        setLoading(true)
        try {
            let response = await Auth.signIn(email, password);
            setUserData(response);
            setAccessToken(response?.signInUserSession?.accessToken?.jwtToken)
            navigate('/dashboard');
        } catch (err) {
            if (err.code === "UserNotConfirmedException") {
                navigate('/confirm-user')
            } else {
                setError(err.message)
            }
        } finally {
            setLoading(false)
        }
    }

    if (loading) {
        return (
            <Loader />
        )
    }

    return (
        <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0 bg-green-100">
            <div className="w-full bg-white rounded-lg shadow-lg md:mt-0 sm:max-w-md xl:p-0">
                <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
                    <h1 className="text-xl text-center font-bold leading-tight tracking-tight text-green-600 md:text-2xl">
                        Iniciar Sesión
                    </h1>

                    <form className="space-y-4 md:space-y-6" onSubmit={handleSubmit}>

                        {/* Email input */}
                        <InputForm
                            name="email"
                            placeholder="john@email.com"
                            type="email"
                            title="Correo electrónico"
                            value={email}
                            setValue={setEmail}
                        />

                        {/* Password input */}
                        <InputForm
                            name="password"
                            placeholder="Escribe tu contraseña"
                            type="password"
                            title="Contraseña"
                            value={password}
                            setValue={setPassword}
                        />

                        {/* Message error */}
                        {error && <ErrorAlert error={error} />}

                        <div className="lg:flex items-center justify-between">
                            <div className="flex items-start">
                                <div className="flex items-center h-5">
                                    <input id="remember"
                                        aria-describedby="remember"
                                        type="checkbox"
                                        className="w-4 h-4 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-green-300 "
                                    />
                                </div>
                                <div className="ml-3 text-sm">
                                    <label htmlFor="remember" className="text-gray-500">Recordar sesión</label>
                                </div>
                            </div>
                            <Link to="/password/forgot-password" className="text-sm font-medium text-green-600 hover:underline">¿Olvidaste tu contraseña?</Link>
                        </div>
                        <div>
                            <button className='bg-green-600 hover:bg-green-700 text-white font-bold py-3 rounded w-full'>Entrar</button>
                        </div>
                        <hr />
                        <p className="text-sm text-center font-light text-gray-500">
                            ¿Aún no tienes cuenta? <Link to="/register" className="font-medium text-green-600 hover:underline">Regístrate aquí</Link>
                        </p>
                    </form>
                </div>
            </div>
        </div>

    )
}

export default LoginForm