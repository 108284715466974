
// Function to get names initials
const getNameInitials = (name) => {
    let nameArray = name.split(" ").slice(0, 2)
    let initials = ""
    nameArray.map((item, index) => {
        initials += item[0]
    })
    return initials.toUpperCase()
}

export default getNameInitials