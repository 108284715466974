import React, { useContext } from "react"
import { useNavigate } from "react-router-dom";
import Footer from "./Footer/Footer";
import Header from "./Header/Header";
import { Auth } from "aws-amplify"
import { DataContext } from "../../context/DataContext"
import { Link } from "react-router-dom";

const Home = () => {

  const navigate = useNavigate();

  const { setUserData } = useContext(DataContext)

  // Provitional function to singOut, only for proobs
  const signOut = async () => {
    try {
      await Auth.signOut();
      setUserData(null);
    } catch (e) {
      console.log(e);
    } finally {
      navigate('/login');
    }
  };

  return (
<div className="flex flex-col min-h-screen">
      <Header />
      <main className="flex-grow container mx-auto my-8 px-4">
        <h2 className="text-2xl font-bold mb-4">Huixquilucan Entrega Segura</h2>
        <div className="max-w-screen-xl mx-auto px-4 sm:px-6 lg:px-8 grid grid-cols-1 sm:grid-cols-2 gap-4">
          {/* Sección Compras */}
          <div className="bg-green-300 p-4 rounded-xl">
            <h3 className="text-lg font-bold mb-2">Compras</h3>
            <img src="https://hes-public-resources.s3.amazonaws.com/compras-digitales.jpg" alt="Imagen Compras" className="mb-2" height="150px" width="300px" />
            <p className="text-base">¿Sín tiempo para realizar tús compras?. Nosotros te apoyamos con la experiencia de años de servicio de nuestros colaboradores</p>
            <Link to="/compras" className="btn mt-2">Ir a Compras</Link>
          </div>
          {/* Sección Servicios */}
          <div className="bg-green-300 p-4 rounded-xl">
            <h3 className="text-lg font-bold mb-2">Servicios</h3>
            <img src="https://hes-public-resources.s3.amazonaws.com/servicios-digitales.jpg" alt="Imagen Servicios" className="mb-2" height="150px" width="300px" />
            <p className="text-base">¿Requieres un servicio a tu negocio, oficina u hogar?. Contamos con una amplica variedad de prestadores de servicios a tu disposición</p>
            <Link to="/servicios" className="btn mt-2">Ir a Servicios</Link>
          </div>
          {/* Sección Restaurantes */}
          <div className="bg-green-300 p-4 rounded-xl">
            <h3 className="text-lg font-bold mb-2">Restaurantes</h3>
            <img src="https://hes-public-resources.s3.amazonaws.com/restaurantes-digitales.jpg" alt="Imagen Restaurantes" className="mb-2" height="150px" width="300px" />
            <p className="text-base">Pide tu comida favorita en los mejores restaurantes de la Zona, contactanos</p>
            <Link to="/restaurantes" className="btn mt-2">Ir a Restaurantes</Link>
          </div>
        </div>
        <br />
        <br />
        <Link to="/dashboard" className="w-full ml-auto"><h2 className="text-2xl font-bold mb-4">Ingresar</h2></Link>
        <br />
        <button className="btn" onClick={signOut}>Cerrar sesión</button>
      </main>
      <Footer />
    </div>
  )
}

export default Home;