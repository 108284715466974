import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { AdminLayout } from "../../components/layouts";



const EmpleadosComponent = () => {


    const [empleados, setEmpleados] = useState([]);
    const [selectedEmpleado, setSelectedEmpleado] = useState(null);

    
    useEffect(() => {
        async function fetchEmpleados() {
            try {
                const response = await axios.get('http://localhost:8000/api/empleados/?format=json');
                setEmpleados(response.data);
            } catch (error) {
                console.error('Error fetching empleados:', error);
            }
        }

        fetchEmpleados();
    }, []);

    const handleEmpleadoSelection = (empleado) => {
        setSelectedEmpleado(empleado);
    };
    

    return (
        
        <AdminLayout title="Empleados">

            <div className="md:w-1/2 px-5 md:px-10">
                <div className="h-2/3 flex flex-col justify-between">
                </div>
            </div>

            <div className="p-5 xl:p-7 pb-0 xl:pb-0 flex items-center animate__animated animate__fadeIn flex-col lg:flex-row">
                <div className="chatContainer bg-brown-100">

                    <div className="container mx-auto p-4">
                        <h1 className="text-2xl font-bold mb-4">Lista de Empleados</h1>

                        <table className="min-w-full bg-white border border-gray-300">
                            <thead>
                                <tr>
                                    <th className="border-b p-2">Nombre</th>
                                    <th className="border-b p-2">Apellidos</th>
                                    <th className="border-b p-2">Fecha de Nacimiento</th>
                                    <th className="border-b p-2">Número de Empleado</th>
                                    <th className="border-b p-2">CURP</th>
                                    <th className="border-b p-2">SSN</th>
                                    <th className="border-b p-2">Teléfono</th>
                                    <th className="border-b p-2">Nacionalidad</th>
                                </tr>
                            </thead>
                            <tbody>
                                {empleados.map((empleado) => (
                                    <tr key={empleado.ID} className={`cursor-pointer ${selectedEmpleado?.ID === empleado.ID ? 'bg-blue-200' : ''}`} onClick={() => handleEmpleadoSelection(empleado)}>
                                        <td className="border-b p-2">{empleado.Nombre}</td>
                                        <td className="border-b p-2">{empleado.Apellidos}</td>
                                        <td className="border-b p-2">{empleado.FechaNacimiento}</td>
                                        <td className="border-b p-2">{empleado.NumeroEmpleado}</td>
                                        <td className="border-b p-2">{empleado.CURP}</td>
                                        <td className="border-b p-2">{empleado.SSN}</td>
                                        <td className="border-b p-2">{empleado.Telefono}</td>
                                        <td className="border-b p-2">{empleado.Nacionalidad}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>

                        <div className="mt-4">
                            <h2 className="text-xl font-bold mb-2">Alta de Dependiente</h2>
                            <div className="flex items-center">
                                <label className="mr-2">Seleccionar Empleado:</label>
                                <span>{selectedEmpleado ? `${selectedEmpleado.Nombre} ${selectedEmpleado.Apellidos}` : 'Ninguno seleccionado'}</span>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </AdminLayout>

    );
}

export default EmpleadosComponent;
