

import { Link, useLocation } from 'react-router-dom';
import { useContext, useEffect, useState } from 'react';
import { DataContext } from '../../../../context/DataContext';
import {
    PlayIcon,
    Squares2X2Icon,
    UserCircleIcon,
    ChatBubbleBottomCenterTextIcon,
    Cog6ToothIcon,
    ArrowRightOnRectangleIcon,
    XMarkIcon,
    UsersIcon,
    ChatBubbleLeftIcon, 
    ListBulletIcon, 
    CurrencyDollarIcon
} from '@heroicons/react/24/outline'

const Sidebar = ({ isHidden, turnToHidden, signOut }) => {

    const { userData } = useContext(DataContext)
    const location = useLocation()
    const { pathname } = location
    const [userInfo, setUserInfo] = useState({})

    // Function to check the route and add the class to the active module
    const checkRoutes = (route) => {
        // Getting the first path of the url to compare with the route
        const routhPath = pathname.split('/')[1]
        // If the route is the same as the path, the module is focused
        if (routhPath === route) {
            return 'bg-blue-500'
        }
    }

    // Function to get the user data
    useEffect(() => {
        if (userData) {
            const { attributes } = userData
            setUserInfo(attributes)
        }
    }, [userData])

    return (
<div className={
  `${isHidden ? '-left-full' : 'left-0'}
  bg-green-800 text-white fixed top-0
  lg:static w-[75%] md:w-[40%] lg:w-80 overflow-y-hidden
  z-50 transition-all duration-500`
}>
  {/* avatar section */}
  <div className="flex flex-col lg:justify-between min-h-screen pb-5">
    <nav>
      <div className="flex items-center justify-between lg:justify-center h-20 px-5">
        <div className="flex items-center gap-3 lg:hidden">
          <img src="https://picsum.photos/40/40" alt="" className="rounded-full" />
          <span className="text-sm">{userInfo.name}</span>
        </div>
        <XMarkIcon className="w-6 h-6 lg:hidden" onClick={turnToHidden} />
        <img src="https://picsum.photos/300/40" className="rounded-lg hidden lg:block" alt="" />
      </div>
      <ul className="pt-8 flex flex-col gap-8 lg:gap-0">
        <Link
          to="/dashboard"
          className={`flex items-center py-4 px-5 hover:shadow-lg ${checkRoutes('dashboard')} cursor-pointer transition-all duration-300`}
        >
          <div className='flex items-center'>
            <Squares2X2Icon className="w-6 h-6 mr-4" />
            Inicio
          </div>
        </Link>
        <Link
          to="/profile"
          className={`flex items-center py-4 px-5 hover:shadow-lg ${checkRoutes('profile')} cursor-pointer transition-all duration-300`}
        >
          <div to="/profile" className=' flex items-center'>
            <UserCircleIcon className="w-6 h-6 mr-4" />
            Mi perfil
          </div>
        </Link>
        <Link
          to="/empleados"
          className={`flex items-center  py-4 px-5 hover:shadow-lg ${checkRoutes('empleados')} cursor-pointer transition-all duration-300`}
        >
          <div to="/empleados" className='flex items-center'>
            <ChatBubbleLeftIcon className="w-6 h-6 mr-4" />
            Empleados
          </div>
        </Link>
        <Link
          to="/suscripccion"
          className={`flex items-center  py-4 px-5 hover:shadow-lg ${checkRoutes('gallery')} cursor-pointer transition-all duration-300`}
        >
          <div to="/suscripccion" className='flex items-center'>
            <CurrencyDollarIcon className="w-6 h-6 mr-4" />
            Dependientes
          </div>
        </Link>
        <Link
          to="/users"
          className={`flex items-center  py-4 px-5 hover:shadow-lg ${checkRoutes('users')} cursor-pointer transition-all duration-300`}
        >
          {/*<div to="/users" className='flex items-center'>
            <UsersIcon className="w-6 h-6 mr-4" />
            Usuarios
          </div>*/}
        </Link>
      </ul>
    </nav>

    <hr className='mt-16 mb-8 lg:hidden' />

    <ul className="flex flex-col gap-8 lg:gap-0 mt-8 lg:mt-0">
      <li className="flex items-center py-4 px-5 hover:shadow-lg cursor-pointer transition-all duration-300">
        <span className='flex items-center' onClick={signOut}>
          <ArrowRightOnRectangleIcon className="w-6 h-6 mr-4" />
          Salir
        </span>
      </li>
    </ul>
  </div>
</div>

    );
}

export default Sidebar;
