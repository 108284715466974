
const env = {
    AWS_REGION: process.env.REACT_APP_AWS_REGION,
    AWS_ACCESS_KEY_ID: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
    AWS_SECRET_ACCESS_KEY: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
    AWS_THUMBNAIL_BUCKET_URL: process.env.REACT_APP_AWS_THUMBNAIL_BUCKET_URL,
    APP_URL: process.env.REACT_APP_URL,
    OPENAI_API_KEY: process.env.OPENAI_API_KEY
}

export default env;