import { PencilSquareIcon } from "@heroicons/react/24/outline";
import { AdminLayout } from "../../../components/layouts";
import { useContext, useEffect, useState } from "react";
import { DataContext } from "../../../context/DataContext";
import { getNameInitials } from "../../../utils";
import { Link } from "react-router-dom";

const Profile = () => {

    const [userInfo, setUserInfo] = useState([])
    const [userInitials, setUserInitials] = useState('')
    const { userData } = useContext(DataContext)
    const [showSuccessMessage, setShowSuccessMessage] = useState(false);

    /******************************************************************
    * Form data for profile user
    *******************************************************************/
    const [formData, setFormData] = useState({
        user_email: '',
        gender: '',
        user_age: '',
        user_weight: '',
        user_height: '',
        user_complexion: '',
        user_religion: '',
        smoke: '',
        drink_alcohol: '',
        does_exercise: '',
        use_drugs: '',
        medicines: '',
        diseases: '',
        educational_level: '',
        personality: '',
        feelings: '',
        sexual_preference: '',
    });


    /******************************************************************
    * Init page process
    * Get cache data
    * Get profile of database by rest
    *******************************************************************/
    useEffect(() => {
        // Get user data
        const { attributes } = userData
        if (userData) {
            // getting name initials from getNameInitials util function
            const initials = getNameInitials(attributes.name)
            setUserInfo(attributes)
            setUserInitials(initials)
            setFormData({ ...formData, user_email: attributes.email, });
        }

        // Get profile user and set to form
        fetch(`https://iu9ozlcvo5.execute-api.us-east-1.amazonaws.com/dev/client/profile?user_email=${attributes.email}`, {
            method: 'GET',
            mode: "cors",
            cache: "no-cache",
            headers: {
                'x-api-key': 'XYHRgAMdCS8jhBf6z8IEL9DPoNPkX4StKmCPtK59',
                'Content-Type': 'application/json'
            },
        })
            .then((response) => response.json())
            .then((data) => {
                // Assign values to form
                console.log("data:", data)
                setFormData({
                    user_email: data.body.user_email.S,
                    gender: data.body.gender.S,
                    user_age: data.body.user_age.S.toString(),
                    user_weight: data.body.user_weight.S.toString(),
                    user_height: data.body.user_height.S.toString(),
                    user_complexion: data.body.user_complexion.S,
                    user_religion: data.body.user_religion.S,
                    smoke: data.body.smoke.S,
                    drink_alcohol: data.body.drink_alcohol.S,
                    does_exercise: data.body.does_exercise.S,
                    use_drugs: data.body.use_drugs.S,
                    medicines: data.body.medicines.S,
                    diseases: data.body.diseases.S,
                    educational_level: data.body.educational_level.S,
                    personality: data.body.personality.S,
                    feelings: data.body.feelings.S,
                    sexual_preference: data.body.sexual_preference.S,
                });

            })
            .catch((error) => {
                console.error('Error al obtener la información del usuario:', error);
            });
    }, [userData])

    
    /******************************************************************
    * Set form data to rest api
    *******************************************************************/
    const handleFormSubmit = (e) => {
        e.preventDefault();
        console.log("formData: ", formData)
        // Enviar los datos del formulario al servicio REST por método POST usando fetch
        fetch('https://iu9ozlcvo5.execute-api.us-east-1.amazonaws.com/dev/client/profile', {
            method: 'POST',
            mode: "cors",
            cache: "no-cache",
            headers: {
                'x-api-key': 'XYHRgAMdCS8jhBf6z8IEL9DPoNPkX4StKmCPtK59',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(formData),
        })
            .then((response) => response.json())
            .then((data) => {
                // Manejar la respuesta del servicio REST si es necesario
                console.log('Respuesta del servicio:', data);
                // El formulario se envió exitosamente
                setShowSuccessMessage(true); // Muestra el mensaje de éxito
            })
            .catch((error) => {
                console.error('Error al enviar el formulario:', error);
            });
    };

    /******************************************************************
    * Update form data variable on change field
    *******************************************************************/
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        console.log(name, value)
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    /******************************************************************
    * Return GUI of form data
    *******************************************************************/
    return (
        <AdminLayout title="Mi perfil" >

            {showSuccessMessage && (
                <div className="bg-green-200 border-l-4 border-green-500 text-green-700 p-4 mb-4">
                    <p className="font-bold">¡Actualización exitosa!</p>
                    <p>Tu formulario se ha enviado correctamente.</p>
                </div>
            )}

            <div className="p-5 xl:p-7 pb-0 xl:pb-0 flex items-center animate__animated animate__fadeIn flex-col lg:flex-row justify-center w-full">
                <div className="w-full lg:w-[900px] md:h-[301px] lg:h-[320px] bg-white flex flex-col md:flex-row rounded p-5 md:p-12 lg:p-14 shadow-lg">
                    <div className="md:w-1/2 bg-gray-100 flex items-center justify-center md:text-[128px] text-[85px] font-bold text-gray-600 rounded mb-5 md:mb-0">
                        {userInitials}
                    </div>
                    <div className="md:w-1/2 px-5 md:px-10">
                        <div className="h-2/3 flex flex-col justify-between">
                            <div>
                                <h3 className="font-bold mb-1">Nombre</h3>
                                <p className="text-gray-500">{userInfo.name}</p>
                            </div>
                            <div>
                                <h3 className="font-bold mb-1">Correo Electrónico</h3>
                                <p className="text-gray-500">{userInfo.email}</p>
                            </div>
                            <div>
                                <h3 className="font-bold mb-1">Rol</h3>
                                <p className="text-gray-500">Administrador</p>
                            </div>
                        </div>
                        <div className="h-1/3 flex items-end mt-5 md:mt-0">
                            <Link to="/profile/change-password" className="w-full lg:w-auto bg-blue-500 highlight-white/20 hover:bg-blue-700 focus:outline-none focus:ring-2 inline-flex gap-2 items-center justify-center focus:ring-slate-400 focus:ring-offset-2 focus:ring-offset-slate-50 text-white font-semibold h-12 px-6 py-2 rounded-lg transition duration-200" >
                                Cambiar contraseña <PencilSquareIcon className="w-5 h-5" />
                            </Link>
                        </div>
                    </div>
                </div>
            </div>

        </AdminLayout>
    );
}

export default Profile;
