import { Link } from "react-router-dom";


const Header = () => {
    return (
        <header className="bg-green-300 text-white py-4 px-8">
            <div className="container flex mx-auto">
                <div className="w-1/2">
                    <h1 className="text-4xl text-brown-600 font-bold">
                        <img src="https://hes-public-resources.s3.amazonaws.com/hes-logo.jpeg" alt="Imagen Compras" className="mb-2" height="100px" width="100px" />
                    </h1>
                </div>
                <div className="w-1/2 flex items-center text-right">
                    <Link to="/chat-gpt-custom" className="text-brown-600 w-full ml-auto"><h1 className="text-2xl text-brown-600">Ingresar</h1></Link>
                </div>
            </div>
        </header>

    )
}

export default Header;